/*hoja de estilos*/
/*font-family: 'Coda', cursive;
font-family: 'Open Sans', sans-serif;
font-family: 'Raleway', sans-serif;*/
html{
  overflow-x: hidden ;
}
body {
  margin: 0px;
  overflow-x: hidden;
}
h1 {
  font-family: 'Coda', cursive;
  color: #ffffff;
  font-weight: 400;
}



/*a.active{
  color: white;
}*/

h2 {
  font-family: "Raleway", sans-serif;
  color: #64eb8d;
}
h4 {
  font-family: 'Coda', cursive;
  font-size: 24px;
  text-align: center;
  color: #6a42e5;
}
p {
  font-family: "Open Sans", sans-serif;
  padding: 10px;
}


#inicio {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(15, 1, 66, 0.733)),
    url(../images/keyboard.jpg);
  background-attachment: fixed;
  background-size: cover;
  height: 600px;
  overflow: visible;
  background-repeat: no-repeat;
}


#contact-container{
  background-image: url(../images/w2.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
#contact-container h4{
  margin: 0;
  padding: 50px 0 0;
  color: #6a42e5;

}
#skills {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-around;
  padding: 0px 70px 30px 70px;
}

.fotoredonda {
  height: 300px;
  width: auto;
  border-radius: 150px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.527);
}

.section {
  padding: 0px;
}

.prog{
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;

}

#skills h5{
  padding-top: 50px;
  font-family: 'Raleway', sans-serif;
  color: #1a1a1a;
  
}
#skills h6{
  margin: 0;
  padding-top: 80px;
  font-family: 'Coda', cursive;
  color: #6a42e5;
  font-size: 14px;
  
  
}
.big{
  font-size: 72px;
}

#portfoliosection, #historia {
  background-image:linear-gradient(rgba(0, 0, 0, 0.651), rgba(0, 0, 0, 0.541)),
  url(../images/texture.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  font-family:  'Coda', cursive;

}
#portfoliogallery{
  display:flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-around;
  padding: 5%;
  
}
.container{
  position: relative;
  height: 200px;
  margin: 20px;
  transition: all 0.5s;
}
.imgportfolio{
  transition: all 0.5s;
  width: 300px;
  height: auto;
  box-shadow: 10px 10px 10px black;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*height: 101%;*/
  height: 85%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  /*background: rgba(0, 13, 54, 0.295*/
  background: linear-gradient(337deg, rgba(20, 13, 121, 0.801) 30%, rgba(15, 0, 61, 0.747) 70%);
}
.container:hover .overlay {
  opacity: 1;
}

.text,.text>a {
  color: white;
  font-size: 18px;
  position: absolute;
  width: -webkit-fill-available;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: 'Coda', cursive;
  text-decoration: none;
}
.whitetitle{
  color:#ffffff;;
  padding-top: 100px;
  margin-top: 0px;
}
.spacer-half{
  height: 50px;
}
#contacto{
  display:flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-around;
}



i{
  margin:5px;
}
#historia{
  display: flex;
  flex-wrap:wrap;
  box-sizing: border-box;
  flex-direction: inherit;
}
.experiencia, .educacion{
 width:500px;
  display: block;
  margin:50px;
}
.exp-box h5{
  color: white;
  border:2px solid #2add51;
  padding:5px;
  width: fit-content;
  border-radius: 5px;
  font-size: 14px;
  
}

.exp-box h3{
  color: rgb(218, 218, 218);
  font-size: 14px;
  

}
.exp-box p{
  color: rgb(202, 202, 202);
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}
span{
  font-family: 'Coda', cursive;
}

::placeholder{
  color: rgb(122, 112, 145);
}
.actionBtn{
  background: #02AAB0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #00CDAC, #02AAB0);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #00CDAC, #02AAB0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  border: none;
  border-radius: 5px 5px 40px 5px;
  padding: 5px;
  color: white;
  font-size: 16px;
  width: -webkit-fill-available;
}
.actionBtn:hover{
  background: rgb(159,107,255);
  background: linear-gradient(304deg, rgba(159,107,255,0.4514180672268907) 0%, rgba(111,0,255,0.8827906162464986) 32%, rgba(152,22,149,1) 100%);  
}


