@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Coda&family=Raleway&display=swap);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  background-color: transparent;
  color: white;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


header {
  width: 100vw;
  background: transparent;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.logo {
  width: 100px;
  margin: 20px;
  transition: 2s;
}
.logo-2 {
  height: 40px;
  width: auto;
  margin: 0;
  padding: 5px 20px;
  transition: 2s;
}
.mainmenu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 80% 1;
          flex: 80% 1;
  list-style: none;
  float: right;
  margin-right: 5%;
  padding-top: 20px;
  /*border: red;
    border-style: solid;*/
}
#btn-menu {
  display: none;
}

header i {
  color: grey;
  font-size: 24px;
}
header label {
  display: none;
  width: 30px;
  height: 30px;
  padding: 10px;
}

header label:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.3);
}

.menu {
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  position: fixed;
  height: 50px;
  z-index: 50;
  font-family: "Coda", cursive;
  transition: 0.2s;

}
.darkmenu {
  background-color: rgba(70, 0, 209, 0.726);
  /*background-color: linear-gradient(
    24deg,
    rgba(132, 0, 255, 0) 14%,
    rgba(93, 0, 185, 0.6558998599439776) 60%,
    rgba(38, 0, 88, 1) 100%
    
  );*/
  transition: 2s;
}
.darkmenu > #logo-menu > img {
  height: 30px;
  width: auto;
  position: relative;
  bottom: 10px;
  /*filter: invert(1);*/
}
.movilmenu {
  display: none;
}
.mainmenu ul {
  margin: 0;
  list-style: none;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

/*.menu li:hover{
    background: green;
}*/
.mainmenu li a,
.movilmenu li a {
  display: block;
  padding: 15px 20px;
  color: white;
  text-decoration: none;
  padding-top: 0;
}
.mainmenu li a:hover {
  color: rgb(0, 233, 163);
}
.mainmenu li a:active {
  color: rgb(0, 233, 163);
}


@media (max-width: 778px) {
  .mainmenu {
    display: none;
    transition: all 0.5s;
  }
  .movilmenu {
    display: block;
    background-color: #8d7cf2;
    transition: all 0.5s;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /*height: 100vh;*/
  }
  .movilmenu.hide {
    margin-left: -100vw;
    transition: all 0.5s;
    background-color: transparent;
  }
  .movilmenu ul {
    list-style: none;
    margin: 0;
    position: absolute;
    background-color: rgb(58, 29, 138);
    top: 0;
    padding-top: 40px;
  }
  .logo {
    display: none;
  }
  .menu-scroll {
    display: none;
  }
  .darkmenu {
    background:none;
  }
  #btn-menu {
    display: block;
    background-color: transparent;
    border: none;
    color: rgb(1, 209, 147);
    outline: none;
    font-size: 2em;
    padding: 10px;
    position: absolute;
    z-index: 1;
  }
}

#bienvenida {
    font-family: "Raleway", sans-serif;
    font-size: 21px;
    text-align: center;
    -webkit-align-self: center;
            align-self: center;
    color: white;
    padding-top: 100px;
    line-height: 50px;
    font-weight: 400;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(15, 1, 66, 0.733)),
    url(/static/media/keyboard.7029157f.jpg);
    background-attachment: fixed;
    background-size: cover;
    height: 75vh;
    background-repeat: no-repeat;
    overflow-y: hidden;
  }
  .blur {
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-name: itemin;
            animation-name: itemin;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  @-webkit-keyframes itemin {
    from {
      -webkit-filter: blur(10px);
              filter: blur(10px);
    }
    to {
      -webkit-filter: blur(0px);
              filter: blur(0px);
    }
  }
  @keyframes itemin {
    from {
      -webkit-filter: blur(10px);
              filter: blur(10px);
    }
    to {
      -webkit-filter: blur(0px);
              filter: blur(0px);
    }
  }

footer
{
    background-color: rgb(31, 31, 31);
    color: white;
    display: grid;  
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 3fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 100%;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    position: fixed;
    bottom: 0;
    height: 30px;
    transition: all 1s;
    font-family: "Raleway", sans-serif;
  }
  footer:hover
  {
      height: 120px;
      transition: all 1s;
  }
  .openClose
  {
    grid-area: 1 / 1 / 2 / 4; 
    text-align: center;
    padding: 5px;
    margin-bottom: 10px;
  }
  .logoFooter
  {
    grid-area: 2 / 1 / 3 / 2;
    text-align: center;
  }
  .copy
  {
    grid-area: 2 / 2 / 3 / 3;
    text-align: center;
    color: gainsboro;
    font-size: 14px;
  }
  .copy>div>p{
    padding: 0;
  }
  .RRSS
  {
    grid-area: 2 / 3 / 3 / 4;
    text-align: center;
  }
  .i{
      font-size: 36px;
      padding: 15px;
      color: #8d7cf2;
  }
  .i:hover{
      color: rgb(0, 233, 163);
  }
  .logoFooter>img{
    width: 70px;
  }
  @media(max-width: 600px){
    footer{
      display: -webkit-flex;
      display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
    position: relative;
    }
    .openCloseFooter{
      display: none;
    }
    footer:hover{
      height: 300px;
    }

  


  }
#about-me{
    background-color: white;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    color: #313131;
    font-family: 'Lato', sans-serif;
  
  }
  .bio {
    display: grid;
    grid-template-columns: auto auto;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    justify-items: center;
  
    height: auto;
  }
  #aboutTitle{
    color: rgb(61 65 156);
    font-family: 'Coda', cursive;
    font-size: 1.2em;
  }
  .bio > div {
    padding: 50px;
  }
  .fotoredonda {
    height: 300px;
    width: auto;
    border-radius: 150px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.527);
  }
  @media (max-width: 800px) {
    .bio {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
  }
  .bio > div {
    padding: 20px;
  }
      
    
  }
/*hoja de estilos*/
/*font-family: 'Coda', cursive;
font-family: 'Open Sans', sans-serif;
font-family: 'Raleway', sans-serif;*/
html{
  overflow-x: hidden ;
}
body {
  margin: 0px;
  overflow-x: hidden;
}
h1 {
  font-family: 'Coda', cursive;
  color: #ffffff;
  font-weight: 400;
}



/*a.active{
  color: white;
}*/

h2 {
  font-family: "Raleway", sans-serif;
  color: #64eb8d;
}
h4 {
  font-family: 'Coda', cursive;
  font-size: 24px;
  text-align: center;
  color: #6a42e5;
}
p {
  font-family: "Open Sans", sans-serif;
  padding: 10px;
}


#inicio {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(15, 1, 66, 0.733)),
    url(/static/media/keyboard.7029157f.jpg);
  background-attachment: fixed;
  background-size: cover;
  height: 600px;
  overflow: visible;
  background-repeat: no-repeat;
}


#contact-container{
  background-image: url(/static/media/w2.9e7f1d78.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
#contact-container h4{
  margin: 0;
  padding: 50px 0 0;
  color: #6a42e5;

}
#skills {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  box-sizing: border-box;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 0px 70px 30px 70px;
}

.fotoredonda {
  height: 300px;
  width: auto;
  border-radius: 150px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.527);
}

.section {
  padding: 0px;
}

.prog{
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;

}

#skills h5{
  padding-top: 50px;
  font-family: 'Raleway', sans-serif;
  color: #1a1a1a;
  
}
#skills h6{
  margin: 0;
  padding-top: 80px;
  font-family: 'Coda', cursive;
  color: #6a42e5;
  font-size: 14px;
  
  
}
.big{
  font-size: 72px;
}

#portfoliosection, #historia {
  background-image:linear-gradient(rgba(0, 0, 0, 0.651), rgba(0, 0, 0, 0.541)),
  url(/static/media/texture.fcd4ec2c.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  font-family:  'Coda', cursive;

}
#portfoliogallery{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  box-sizing: border-box;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 5%;
  
}
.container{
  position: relative;
  height: 200px;
  margin: 20px;
  transition: all 0.5s;
}
.imgportfolio{
  transition: all 0.5s;
  width: 300px;
  height: auto;
  box-shadow: 10px 10px 10px black;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*height: 101%;*/
  height: 85%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  /*background: rgba(0, 13, 54, 0.295*/
  background: linear-gradient(337deg, rgba(20, 13, 121, 0.801) 30%, rgba(15, 0, 61, 0.747) 70%);
}
.container:hover .overlay {
  opacity: 1;
}

.text,.text>a {
  color: white;
  font-size: 18px;
  position: absolute;
  width: -webkit-fill-available;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  font-family: 'Coda', cursive;
  text-decoration: none;
}
.whitetitle{
  color:#ffffff;;
  padding-top: 100px;
  margin-top: 0px;
}
.spacer-half{
  height: 50px;
}
#contacto{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  box-sizing: border-box;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}



i{
  margin:5px;
}
#historia{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap:wrap;
          flex-wrap:wrap;
  box-sizing: border-box;
  -webkit-flex-direction: inherit;
          flex-direction: inherit;
}
.experiencia, .educacion{
 width:500px;
  display: block;
  margin:50px;
}
.exp-box h5{
  color: white;
  border:2px solid #2add51;
  padding:5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  font-size: 14px;
  
}

.exp-box h3{
  color: rgb(218, 218, 218);
  font-size: 14px;
  

}
.exp-box p{
  color: rgb(202, 202, 202);
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}
span{
  font-family: 'Coda', cursive;
}

::-webkit-input-placeholder{
  color: rgb(122, 112, 145);
}

:-ms-input-placeholder{
  color: rgb(122, 112, 145);
}

::placeholder{
  color: rgb(122, 112, 145);
}
.actionBtn{
  background: #02AAB0;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #00CDAC, #02AAB0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  border: none;
  border-radius: 5px 5px 40px 5px;
  padding: 5px;
  color: white;
  font-size: 16px;
  width: -webkit-fill-available;
}
.actionBtn:hover{
  background: rgb(159,107,255);
  background: linear-gradient(304deg, rgba(159,107,255,0.4514180672268907) 0%, rgba(111,0,255,0.8827906162464986) 32%, rgba(152,22,149,1) 100%);  
}



h4{
    color: red;
    padding: 20px;
}
#services{
}
.iC{
    color: #eee;
    font-size: 1.2em;
    padding: 0;
}
a:hover{
    color:  rgb(0, 233, 163);

}
a:hover>.iC{
    color:  rgb(0, 233, 163);
}
#formulario {
    padding: 30px;
    margin: 50px;
    /*background-color: #8964f7;*/
    border-radius: 25px;
    box-shadow: 0px 0px 10px black;
    width: 400px;
  }
  
  label{
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color:#313131;
  
  }
  
  #direccion{
  
    padding: 30px;
    margin: 50px;
    width: 400px;
   /* margin-top: 100px;*/
  
  }
  
  input, textarea{
    width: -webkit-fill-available;
    background-color: #ffffff;
    border: none;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.521);
    border-radius:5px 5px 30px 5px;
  }
  input {-webkit-user-select: text;}
  input:focus, textarea:focus{
    border:2px solid #6a42e5;
    box-shadow: 0 0 5px #6a42e5;
  }
  
  :focus { outline: none; }
  
  input
  {
    height: 30px;
  }
  textarea
  {
    height:100px;
  }
  
  address, address a{
    color: white;
  }
  
  
  
  .field-set{
    margin-top: 10px;
  }
  .btn-mio {
    color: white;
    font-family: 'Raleway', sans-serif;
    font-size: 21px;
    background-color: #623EE5;
    border-radius: 5px 5px 40px 5px;
    border: none;
    width: 100px;
  
  }
  
  .btn-mio:hover 
  {
    background-color: #2add51;
    font-weight: 1000;
  }
  
  .mi-dato
  {
  background-color:#623EE5;
  margin-top: 30px;
  padding:15px;
  width:300px;
  border-radius: 5px 5px 40px 5px;
  }

  @media(max-width: 450px){
    #formulario {

        box-shadow: none;

      }
  }
.sep{
    background-image: url(/static/media/separador.89efe00d.svg);
    margin-left: 0;
    width: 100vw;
    position: absolute;
    height: 200px;
    position: absolute;
    background-repeat: no-repeat;
    bottom: 40px;
    left: -10px;
}
@media(max-width:800px){
    .sep{

        bottom: 30px;
    }
}
@media(max-width:750px){
    .sep{
        display: none;
    }
}
