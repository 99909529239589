#about-me{
    background-color: white;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    color: #313131;
    font-family: 'Lato', sans-serif;
  
  }
  .bio {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-around;
    justify-items: center;
  
    height: auto;
  }
  #aboutTitle{
    color: rgb(61 65 156);
    font-family: 'Coda', cursive;
    font-size: 1.2em;
  }
  .bio > div {
    padding: 50px;
  }
  .fotoredonda {
    height: 300px;
    width: auto;
    border-radius: 150px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.527);
  }
  @media (max-width: 800px) {
    .bio {
      display: flex;
      flex-wrap: wrap;
  }
  .bio > div {
    padding: 20px;
  }
      
    
  }