.iC{
    color: #eee;
    font-size: 1.2em;
    padding: 0;
}
a:hover{
    color:  rgb(0, 233, 163);

}
a:hover>.iC{
    color:  rgb(0, 233, 163);
}
#formulario {
    padding: 30px;
    margin: 50px;
    /*background-color: #8964f7;*/
    border-radius: 25px;
    box-shadow: 0px 0px 10px black;
    width: 400px;
  }
  
  label{
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color:#313131;
  
  }
  
  #direccion{
  
    padding: 30px;
    margin: 50px;
    width: 400px;
   /* margin-top: 100px;*/
  
  }
  
  input, textarea{
    width: -webkit-fill-available;
    background-color: #ffffff;
    border: none;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.521);
    border-radius:5px 5px 30px 5px;
  }
  input {-webkit-user-select: text;}
  input:focus, textarea:focus{
    border:2px solid #6a42e5;
    box-shadow: 0 0 5px #6a42e5;
  }
  
  :focus { outline: none; }
  
  input
  {
    height: 30px;
  }
  textarea
  {
    height:100px;
  }
  
  address, address a{
    color: white;
  }
  
  
  
  .field-set{
    margin-top: 10px;
  }
  .btn-mio {
    color: white;
    font-family: 'Raleway', sans-serif;
    font-size: 21px;
    background-color: #623EE5;
    border-radius: 5px 5px 40px 5px;
    border: none;
    width: 100px;
  
  }
  
  .btn-mio:hover 
  {
    background-color: #2add51;
    font-weight: 1000;
  }
  
  .mi-dato
  {
  background-color:#623EE5;
  margin-top: 30px;
  padding:15px;
  width:300px;
  border-radius: 5px 5px 40px 5px;
  }

  @media(max-width: 450px){
    #formulario {

        box-shadow: none;

      }
  }