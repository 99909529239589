.sep{
    background-image: url(../images/separador.svg);
    margin-left: 0;
    width: 100vw;
    position: absolute;
    height: 200px;
    position: absolute;
    background-repeat: no-repeat;
    bottom: 40px;
    left: -10px;
}
@media(max-width:800px){
    .sep{

        bottom: 30px;
    }
}
@media(max-width:750px){
    .sep{
        display: none;
    }
}